import { createElement } from '@vivaldis/common'
import { ComponentType, ReactElement, useMemo } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook'
import { List } from '../List'
import type { ListProps } from '../List'
import { ErrorState, ErrorStateProps } from './ErrorState'
import { FooterLoadingMoreIndicator } from './FooterLoadingMoreIndicator'

export interface InfiniteListProps<T> extends ListProps<T> {
  isLoadingMore: boolean
  onLoadMore: () => void
  hasNextPage: boolean
  sentryRef?: UseInfiniteScrollHookRefCallback
  footerLoadingMoreIndicator?: ComponentType<any> | ReactElement | null
  errorState?: ComponentType<ErrorStateProps> | ReactElement | null
  error?: Error
}

export function InfiniteList<T>({
  // custom props
  isLoadingMore,
  onLoadMore,
  hasNextPage,
  sentryRef: sentryRefProp,
  //
  footerLoadingMoreIndicator = FooterLoadingMoreIndicator,
  errorState = ErrorState,
  error,
  // List props
  dataSource: dataSourceProp,
  locale: localeProp,
  ...props
}: InfiniteListProps<T>) {
  const [sentryRef] = useInfiniteScroll({
    loading: !!props.loading || isLoadingMore,
    hasNextPage,
    onLoadMore: onLoadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 200px 0px'
  })

  const footer = useMemo<ListProps<T>['footer']>(() => {
    if (isLoadingMore) {
      return createElement(footerLoadingMoreIndicator, {
        ref: sentryRefProp || sentryRef
      })
    }

    if (hasNextPage) {
      return <div ref={sentryRefProp || sentryRef} />
    }

    return undefined
  }, [
    footerLoadingMoreIndicator,
    hasNextPage,
    isLoadingMore,
    sentryRef,
    sentryRefProp
  ])

  // when we have error we want to show custom empty state, and we need to pass undefined instead of real value of dataSource
  const dataSource = useMemo<ListProps<T>['dataSource']>(() => {
    if (error) {
      return undefined
    }
    return dataSourceProp
  }, [dataSourceProp, error])

  const locale = useMemo<ListProps<T>['locale']>(() => {
    if (error) {
      return {
        ...localeProp,
        emptyText: createElement(errorState)
      }
    }

    return localeProp
  }, [error, errorState, localeProp])

  return (
    <List footer={footer} {...props} dataSource={dataSource} locale={locale} />
  )
}
