import { FC, forwardRef, PropsWithChildren } from 'react'
import { Text } from '../platform/Text'
import { TextProps } from '../typings/TextProps'
import { FontWeight } from '../../font/typings/FontWeight'
import { Color } from '../../colors/Color'

export const BodyAlt: FC<PropsWithChildren<TextProps>> = forwardRef(
  (props, ref) => <Text ref={ref} {...props} />
)

BodyAlt.defaultProps = {
  color: Color.Text.Default,
  fontSize: 16,
  fontWeight: FontWeight.Bold,
  letterSpacing: -0.2,
  lineHeight: 22
}
