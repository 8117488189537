import { FC } from 'react'
import { Color } from '@vivaldis/ui'
import { FontWeight } from '../../../styles/font'
import { Text, TextProps } from '../../index'

export const BodyAlt: FC<TextProps> = props => <Text {...props} />

BodyAlt.defaultProps = {
  color: Color.Text.Default,
  fontSize: 16,
  fontWeight: FontWeight.bold,
  letterSpacing: 0.35,
  lineHeight: 22
}
