import { FC } from 'react'
import { Color } from '@vivaldis/ui'
import { FontWeight } from '../../../styles/font'
import { Text, TextProps } from '../../index'

export const CaptionAlt: FC<TextProps> = (props: TextProps) => (
  <Text {...props} />
)

CaptionAlt.defaultProps = {
  color: Color.Text.Default,
  fontSize: 14,
  fontWeight: FontWeight.bold,
  lineHeight: 19
}
