import { createElement } from '@vivaldis/common'
import { ComponentType, ReactElement, useMemo } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { Table, TableProps } from '../Table'
import { ErrorState, ErrorStateProps } from './ErrorState'
import { FooterLoadingMoreIndicator } from './FooterLoadingMoreIndicator'

export interface InfiniteTableProps<
  RecordType extends Record<string, any> = any
> extends TableProps<RecordType> {
  isLoadingMore: boolean
  onLoadMore: () => void
  hasNextPage: boolean
  footerLoadingMoreIndicator?: ComponentType<any> | ReactElement | null
  errorState?: ComponentType<ErrorStateProps> | ReactElement | null
  error?: Error
}

export function InfiniteTable<RecordType extends Record<string, any> = any>({
  // custom props
  isLoadingMore,
  onLoadMore,
  hasNextPage,
  //
  footerLoadingMoreIndicator = FooterLoadingMoreIndicator,
  errorState = ErrorState,
  error,
  // table props
  dataSource: dataSourceProp,
  locale: localeProp,
  ...props
}: InfiniteTableProps<RecordType>) {
  const [sentryRef] = useInfiniteScroll({
    loading: !!props.loading || isLoadingMore,
    hasNextPage,
    onLoadMore: onLoadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 200px 0px'
  })

  const footer = useMemo<InfiniteTableProps<RecordType>['footer']>(() => {
    if (isLoadingMore) {
      return () => createElement(footerLoadingMoreIndicator, { ref: sentryRef })
    }

    if (hasNextPage) {
      return () => <div ref={sentryRef} />
    }
    return undefined
  }, [footerLoadingMoreIndicator, hasNextPage, isLoadingMore, sentryRef])

  // when we have error we want to show custom empty state, and we need to pass undefined instead of real value of dataSource
  const dataSource = useMemo<
    InfiniteTableProps<RecordType>['dataSource']
  >(() => {
    if (error) {
      return undefined
    }
    return dataSourceProp
  }, [dataSourceProp, error])

  const locale = useMemo<InfiniteTableProps<RecordType>['locale']>(() => {
    return {
      ...localeProp,
      ...(error && {
        emptyText: () => createElement(errorState)
      })
    }
  }, [error, errorState, localeProp])

  return (
    <Table
      pagination={false}
      footer={footer}
      {...props}
      dataSource={dataSource}
      locale={locale}
    />
  )
}
