import { FC } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Alert } from '../Alert'

export interface ErrorStateProps {}

export const ErrorState: FC<ErrorStateProps> = () => {
  const [t] = useTranslation('antd_ui')

  return (
    <Alert.ErrorBoundary>
      <ErrorMessage
        message={t('infinite_list.error_state.title')}
        description={t('infinite_list.error_state.description')}
        showIcon
        type="error"
      />
    </Alert.ErrorBoundary>
  )
}

const ErrorMessage = styled(Alert)`
  display: flex;
  text-align: start;
  margin: 16px 0;
`
