import { FC } from 'react'
import { Color } from '@vivaldis/ui'
import { FontWeight } from '../../../styles/font'
import { Text, TextProps } from '../../index'

export const Caption: FC<TextProps> = (props: TextProps) => <Text {...props} />

Caption.defaultProps = {
  color: Color.Text.Default,
  fontSize: 14,
  fontWeight: FontWeight.regular,
  letterSpacing: 0.2,
  lineHeight: 19
}
