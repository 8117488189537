import { FC, forwardRef } from 'react'
import { Color } from '@vivaldis/ui'
import { FontWeight } from '../../../styles/font'
import { Text, TextProps } from '../../index'

export const Body: FC<TextProps> = forwardRef((props, ref) => (
  <Text ref={ref} {...props} />
))

Body.defaultProps = {
  color: Color.Text.Default,
  fontSize: 16,
  fontWeight: FontWeight.regular,
  letterSpacing: 0.35,
  lineHeight: 22
}
